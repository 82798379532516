<template>
   <div class="grid h-screen place-content-center bg-white px-4">
      <div class="flex flex-col space-y-8 text-center">
         <h1 class="text-9xl font-black text-gray-200">
            {{ $page.props.status }}
         </h1>

         <p class="text-2xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            {{ title }}
         </p>

         <p class="mt-4 text-gray-500">
            {{ description }}
         </p>

         <Button
            size="small"
            @click.prevent="router.visit(route('home.index'))"
            :label="$t('misc.back_home')"
         />
      </div>
   </div>
</template>

<script setup>
   import { computed } from 'vue';
   import { Button } from 'primevue';
   import { router } from '@inertiajs/vue3';
   const props = defineProps({status: Number});

   const title = computed(() => {
      return {
         503: 'Service Unavailable',
         500: 'Server Error',
         404: 'Page Not Found',
         403: 'Forbidden',
      }[props.status];
   });

   const description = computed(() => {
      return {
         503: 'Sorry, we are doing some maintenance. Please check back soon.',
         500: 'Whoops, something went wrong on our servers.',
         404: 'Sorry, the page you are looking for could not be found.',
         403: 'Sorry, you are forbidden from accessing this page.',
      }[props.status];
   });
</script>
